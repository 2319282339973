<template>
  <section class="main-page">
    <section class="page-section q-pa-md">
      <div class="container-fluid">
        <div class="grid">
          <div class="col comp-grid">
            <div class="">
              <div class="flex align-items-center">
                <div>
                  <Avatar size="large" class="primary" icon="pi pi-home" />
                </div>
                <div class="ml-2">
                  <div class="text-2xl font-bold">Home</div>
                  <div class="text-sm text-500">
                    Quick analytics of the CAPS Application
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-3" />
          </div>
        </div>
      </div>
    </section>
    <api-data-source
      api-path="components_data/getdashboardcount"
      v-slot="record"
    >
      <section v-if="$can.view('/caps_institutions_list/list')" class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid">
            <div class="col-12 comp-grid">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl font-bold">
                      Institutions and Programmes
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-3 comp-grid">
              <router-link :to="`caps_institutions_list`">
                <div class="card bg-blue-100 nice-shadow-6">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="font-medium mb-3">Institutions</div>
                      <div class="font-medium text-xl" v-if="!record.loading">
                        {{ record.response.getcount_institutions }}
                      </div>
                      <ProgressSpinner
                        v-else
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div style="width: auto">
                      <Avatar
                        icon="pi pi-th-large"
                        size="large"
                        class="text-white bg-primary"
                      />
                    </div>
                  </div>
                  <span class="text-500 text-sm"
                    >Total registered Institutions</span
                  >
                </div>
              </router-link>
            </div>
            <div class="col-12 md:col-3 comp-grid">
              <router-link
                :to="`/caps_institutions_list/is_accredited/Yes?label=Accredited Institutions&tag=Institutions`"
              >
                <div class="card bg-green-100 nice-shadow-6">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="font-medium mb-3">
                        Accredited Institutions
                      </div>
                      <div class="font-medium text-xl" v-if="!record.loading">
                        {{ record.response.getcount_accreditedinstitutions }}
                      </div>
                      <ProgressSpinner
                        v-else
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div style="width: auto">
                      <Avatar
                        icon="pi pi-th-large"
                        size="large"
                        class="text-white bg-green-600"
                      />
                    </div>
                  </div>
                  <span class="text-500 text-sm"
                    >Number of accredited institutions</span
                  >
                </div>
              </router-link>
            </div>
            <div class="col-12 md:col-3 comp-grid">
              <router-link
                :to="`/caps_institutions_list/is_accredited/No?label=Unaccredited Institutions&tag=Institutions`"
              >
                <div class="card bg-pink-100 nice-shadow-6">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="font-medium mb-3">
                        Unaccredited Institutions
                      </div>
                      <div class="font-medium text-xl" v-if="!record.loading">
                        {{ record.response.getcount_unaccreditedinstitutions }}
                      </div>
                      <ProgressSpinner
                        v-else
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div style="width: auto">
                      <Avatar
                        icon="pi pi-th-large"
                        size="large"
                        class="text-white bg-pink-600"
                      />
                    </div>
                  </div>
                  <span class="text-500 text-sm"
                    >Total unaccredited institutions</span
                  >
                </div>
              </router-link>
            </div>
            <div class="col-12 md:col-3 comp-grid">
              <router-link :to="`caps_programmes_list`">
                <div class="card bg-blue-100 nice-shadow-6">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="font-medium mb-3">Programmes</div>
                      <div class="font-medium text-xl" v-if="!record.loading">
                        {{ record.response.getcount_gtecprogrammes }}
                      </div>
                      <ProgressSpinner
                        v-else
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div style="width: auto">
                      <Avatar
                        icon="pi pi-th-large"
                        size="large"
                        class="text-white bg-primary"
                      />
                    </div>
                  </div>
                  <span class="text-500 text-sm"
                    >Total number of programmes</span
                  >
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section v-if="$can.view('/applicants/list')" class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid">
            <div class="col-12 comp-grid">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl font-bold">
                      Credential Evaluation Overview
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-3 comp-grid">
              <router-link :to="`applicants`">
                <div class="card bg-blue-100 nice-shadow-6">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="font-medium mb-3">Applications</div>
                      <div class="font-medium text-xl" v-if="!record.loading">
                        {{ record.response.getcount_all }}
                      </div>
                      <ProgressSpinner
                        v-else
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div style="width: auto">
                      <Avatar
                        icon="pi pi-th-large"
                        size="large"
                        class="text-white bg-blue-900"
                      />
                    </div>
                  </div>
                  <span class="text-500 text-sm"
                    >Total number of applications</span
                  >
                </div>
              </router-link>
            </div>
            <div class="col-12 md:col-3 comp-grid">
              <router-link
                :to="`/applicants/tertiary_certificate_verify_status/Approved?label=Approved Certificates&tag=Certificates`"
              >
                <div class="card bg-green-100 nice-shadow-6">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="font-medium mb-3">
                        Approved Certificates
                      </div>
                      <div class="font-medium text-xl" v-if="!record.loading">
                        {{ record.response.getcount_approved }}
                      </div>
                      <ProgressSpinner
                        v-else
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div style="width: auto">
                      <Avatar
                        icon="pi pi-th-large"
                        size="large"
                        class="text-white bg-green-600"
                      />
                    </div>
                  </div>
                  <span class="text-500 text-sm"
                    >Total approved certificates</span
                  >
                </div>
              </router-link>
            </div>
            <div class="col-12 md:col-3 comp-grid">
              <router-link
              :to="`/applicants/tertiary_certificate_verify_status/Declined?label=Declined Certificates&tag=Certificates`"
              >
                <div class="card bg-pink-100 nice-shadow-6">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="font-medium mb-3">
                        Declined Certificates
                      </div>
                      <div class="font-medium text-xl" v-if="!record.loading">
                        {{ record.response.getcount_declined }}
                      </div>
                      <ProgressSpinner
                        v-else
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div style="width: auto">
                      <Avatar
                        icon="pi pi-th-large"
                        size="large"
                        class="text-white bg-pink-600"
                      />
                    </div>
                  </div>
                  <span class="text-500 text-sm"
                    >Total declined certificates</span
                  >
                </div>
              </router-link>
            </div>
            <div class="col-12 md:col-3 comp-grid">
              <router-link  :to="`/applicants/tertiary_certificate_verify_status/Pending?label=Pending Approvals&tag=Certificates`">
                <div class="card bg-yellow-100 nice-shadow-6">
                  <div class="flex justify-content-between mb-3">
                    <div>
                      <div class="font-medium mb-3">Pending Approvals</div>
                      <div class="font-medium text-xl" v-if="!record.loading">
                        {{ record.response.getcount_pending }}
                      </div>
                      <ProgressSpinner
                        v-else
                        style="width: 20px; height: 20px"
                      />
                    </div>
                    <div style="width: auto">
                      <Avatar
                        icon="pi pi-th-large"
                        size="large"
                        class="text-white bg-yellow-800"
                      />
                    </div>
                  </div>
                  <span class="text-500 text-sm"
                    >Total number of pending approvals</span
                  >
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </api-data-source>
  </section>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
export default {
  name: "homePage",
  components: {},
  mixins: [PageMixin],
  props: {},
  data: function () {
    return {
      ready: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Home";
      },
    },
  },
  methods: {},
  watch: {},
  async mounted() {
    this.ready = true;
  },
  async created() {},
};
</script>
<style scoped></style>
